<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label>
              <strong>Reservation Type</strong>
            </label>
            <b-select
              v-model="tableHistories.filter.reservation_type"
              :options="list.reservationTypes"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label for="date_from">
              <strong>Date From</strong>
            </label>
            <b-input
              id="date_from"
              v-model="tableHistories.filter.date_from"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label for="date_from">
              <strong>Date To</strong>
            </label>
            <b-input
              id="date_from"
              v-model="tableHistories.filter.date_to"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label>
              <strong>Status</strong>
            </label>
            <b-select
              v-model="tableHistories.filter.status"
              :options="list.statuses"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="9"
        >
          <b-form-group>
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableHistories.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label>
              <strong>Company</strong>
            </label>
            <b-select
              v-model="tableHistories.filter.company"
              :options="filterCompanies"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :class="[
              'btn btn-success', {
                'btn-outline-success disabled': state.exporting || !isValidForExport
              }
            ]"
            :fetch="exportProvider"
            :before-generate="startExport"
            :before-finish="finishExport"
            :fields="exportAs.excel.fields"
            :escape-csv="exportAs.excel.settings.escape"
            :type="exportAs.excel.settings.type"
            :name="excelFileName"
            :header="exportAs.excel.header"
          >
            <span
              v-if="state.exporting"
              class="align-middle"
            >Exporting <i class="ri-loader-2-line" /></span>
            <span
              v-else
              class="align-middle"
            >Export as Excel</span>
          </json-excel>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableHistories.perPage"
        :current-page="tableHistories.currentPage"
        :items="tableProvider"
        :fields="tableHistories.fields"
        :sort-by.sync="tableHistories.sortBy"
        :sort-desc.sync="tableHistories.sortDesc"
        :sort-direction="tableHistories.sortDirection"
        :filter="tableHistories.filter"
        :filter-included-fields="tableHistories.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableHistories.currentPage * tableHistories.perPage - tableHistories.perPage + (data.index + 1) }}
        </template>

        <template #cell(departure)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.departure_date} ${data.item.departure_time}`) }}
          </div>
        </template>

        <template #cell(arrival)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.arrival_date} ${data.item.arrival_time}`) }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onView(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableHistories.perPage"
              :options="tableHistories.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableHistories.currentPage"
            :total-rows="tableHistories.totalRows"
            :per-page="tableHistories.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
      title="View Detail"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-success"
            content-class="scrollable"
          >
            <b-tab
              active
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-file-text-line h4" />
                  <b class="is-tab-active ml-1">
                    Details
                  </b>
                </div>
              </template>

              <b-row class="p-2">

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="requested_by">
                      <strong>Reserved By</strong>
                    </label>
                    <b-input
                      id="requested_by"
                      v-model="transactionHistory.user_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="created_at">
                      <strong>Reserved At</strong>
                    </label>
                    <b-input
                      id="created_at"
                      v-model="transactionHistory.created_at"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="company_name">
                      <strong>Company</strong>
                    </label>
                    <b-input
                      id="company_name"
                      v-model="transactionHistory.company_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="organization_name">
                      <strong>Organization</strong>
                    </label>
                    <b-input
                      id="organization_name"
                      v-model="transactionHistory.organization_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="departure">
                      <strong>Departure</strong>
                    </label>
                    <b-input
                      id="departure"
                      v-model="departureDateTime"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="arrival">
                      <strong>Arrival</strong>
                    </label>
                    <b-input
                      id="arrival"
                      v-model="arrivalDateTime"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="reservation_type">
                      <strong>Reservation Type</strong>
                    </label>
                    <b-input
                      id="reservation_type"
                      v-model="transactionHistory.reservation_type"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group>
                    <label for="passenger_count">
                      <strong>Passenger Count</strong>
                    </label>
                    <b-input
                      id="passenger_count"
                      v-model="transactionHistory.passenger_count"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="status">
                      <strong>Status</strong>
                    </label>
                    <b-input
                      id="status"
                      v-model="transactionHistory.status"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="purpose">
                      <strong>Purpose</strong>
                    </label>
                    <b-textarea
                      id="purpose"
                      v-model="transactionHistory.purpose"
                      type="text"
                      rows="6"
                      max-rows="6"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="processed_at">
                      <strong>Processed At</strong>
                    </label>
                    <b-input
                      id="processed_at"
                      v-model="transactionHistory.processed_at"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="processed_by_name">
                      <strong>Processed By</strong>
                    </label>
                    <b-input
                      id="processed_by_name"
                      v-model="transactionHistory.processed_by_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="remarks">
                      <strong>Processed Remarks</strong>
                    </label>
                    <b-textarea
                      id="remarks"
                      v-model="transactionHistory.processed_by_remarks"
                      type="text"
                      rows="6"
                      max-rows="6"
                      disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab>
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-attachment-line h4" />
                  <b class="is-tab-active ml-1">
                    Attachments
                  </b>
                </div>
              </template>
              <b-table
                class="mt-1"
                :fields="tableAttachments.fields"
                :items="transactionHistory.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-tab>

            <b-tab
              v-if="transactionHistory.status === 'Approved' && isDriverVisible"
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-user-forbid-line h4" />
                  <b class="is-tab-active ml-1">
                    Assigned Driver
                  </b>
                </div>
              </template>

              <b-row class="p-2">
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="driver_name">
                      <strong>Driver Name</strong>
                    </label>
                    <b-input
                      id="driver_name"
                      v-model="transactionHistory.driver_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="driver_license_type">
                      <strong>License Type</strong>
                    </label>
                    <b-input
                      id="driver_license_type"
                      v-model="transactionHistory.driver_license_type"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="driver_license_number">
                      <strong>License Number</strong>
                    </label>
                    <b-input
                      id="driver_license_number"
                      v-model="transactionHistory.driver_license_number"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="driver_license_expiry">
                      <strong>License Expiry</strong>
                    </label>
                    <b-input
                      id="driver_license_expiry"
                      v-model="transactionHistory.driver_license_expiry"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="transactionHistory.status === 'Approved' && isVehicleVisible"
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-roadster-line h4" />
                  <b class="is-tab-active ml-1">
                    Assigned Vehicle
                  </b>
                </div>
              </template>

              <b-row class="p-2">
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="vehicle_name">
                      <strong>Vehicle Name</strong>
                    </label>
                    <b-input
                      id="vehicle_name"
                      v-model="transactionHistory.vehicle_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="vehicle_license_plate">
                      <strong>License Plate</strong>
                    </label>
                    <b-input
                      id="vehicle_license_plate"
                      v-model="transactionHistory.vehicle_license_plate"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="vehicle_registration_expiry">
                      <strong>Registration Expiry</strong>
                    </label>
                    <b-input
                      id="vehicle_registration_expiry"
                      v-model="transactionHistory.vehicle_registration_expiry"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="vehicle_seat_capacity">
                      <strong>Seat Capacity</strong>
                    </label>
                    <b-input
                      id="vehicle_seat_capacity"
                      v-model="transactionHistory.vehicle_seat_capacity"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </b-tab>
          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{cancel}">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- modal preview image -->
    <b-modal
      id="modal-preview-image"
      size="lg"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="`Preview Attachment [${file.name}]`"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SLOReservation, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import Ripple from 'vue-ripple-directive'
import JsonExcel from 'vue-json-excel'

export default {
  name: 'VSLOReservationHistories',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Reports - Transaction Histories'
    }
  },

  components: {
    JsonExcel
  },

  directives: {
    Ripple
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        editing: false,
        busy: false,
        exporting: false
      },
      file: {
        name: '',
        preview: null
      },
      list: {
        statuses: [
          { text: 'All', value: 'All' },
          { text: 'Approved', value: 'Approved' },
          { text: 'Disapproved', value: 'Disapproved' }
        ],
        reservationTypes: [
          { text: 'All', value: 'All' },
          { text: 'Service Driver', value: 'Service Driver' },
          { text: 'Service Vehicle', value: 'Service Vehicle' },
          { text: 'Service Driver And Vehicle', value: 'Service Driver And Vehicle' }
        ],
        companies: []
      },
      transactionHistory: {
        id: 0,
        created_at: '',
        status: '',
        user_name: '',
        company_name: '',
        organization_name: '',
        reservation_type: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        vehicle_type_name: '',
        passenger_count: 0,
        purpose: '',
        vehicle_name: '',
        vehicle_license_plate: '',
        vehicle_registration_expiry: '',
        vehicle_seat_capacity: 0,
        driver_name: '',
        driver_license_type: '',
        driver_license_number: '',
        driver_license_expiry: '',
        processed_at: '',
        processed_by_name: '',
        processed_by_remarks: '',
        file_path: '',
        attachments: []
      },
      tableHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          status: this.$route.params?.status || 'All',
          reservation_type: 'All',
          company: 'All',
          date_from: '',
          date_to: '',
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'reserve at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'user_name', label: 'reserved by' },
          { key: 'company_name', label: 'company' },
          { key: 'organization_name', label: 'organization' },
          { key: 'reservation_type' },
          { key: 'vehicle_type_name', label: 'vehicle type' },
          { key: 'vehicle_license_plate', label: 'vehicle plate' },
          'departure',
          'arrival',
          { key: 'status' },
          { key: 'processed_at' },
          { key: 'processed_by_name', label: 'processed_by' }
        ]
      },
      tableAttachments: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      },
      exportAs: {
        excel: {
          header: 'Reservation Histories',
          settings: {
            type: 'xls',
            escape: false
          },
          fields: {
            Company: {
              field: 'company_name'
            },
            Organization: {
              field: 'organization_name'
            },
            Employee: {
              field: 'user_name'
            },
            'Reservation Status': {
              field: 'status'
            },
            'Reservation Type': {
              field: 'reservation_type'
            },
            'Reservation Purpose': {
              field: 'purpose'
            },
            'Passenger Count': {
              field: 'passenger_count'
            },
            'Reserve At': {
              field: 'created_at',
              callback: value => `${this.dateTimeFormatter(value)}`
            },
            Vehicle: {
              field: 'vehicle_name'
            },
            'Vehicle Type': {
              field: 'vehicle_type_name'
            },
            'Vehicle Plate': {
              field: 'vehicle_license_plate'
            },
            'Vehicle Registration Expiry': {
              field: 'vehicle_registration_expiry'
            },
            Driver: {
              field: 'driver_name'
            },
            'Driver License': {
              field: 'driver_license_number'
            },
            'Driver License Type': {
              field: 'driver_license_type'
            },
            'Driver License Expiry': {
              field: 'driver_license_expiry'
            },
            'Departure Date': {
              field: 'departure_date'
            },
            'Departure Time': {
              field: 'departure_time'
            },
            'Arrival Date': {
              field: 'arrival_date'
            },
            'Arrival Time': {
              field: 'arrival_time'
            },
            'Processed By': {
              field: 'processed_by_name'
            },
            'Processed Remarks': {
              field: 'processed_remarks'
            },
            'Processed At': {
              field: 'processed_at',
              callback: value => `${this.dateTimeFormatter(value)}`
            }
          }
        }
      }
    }
  },

  computed: {
    departureDateTime () {
      if (this.transactionHistory.departure_date !== '' && this.transactionHistory.departure_time !== '') {
        return this.dateTimeFormatterLong(`${this.transactionHistory.departure_date} ${this.transactionHistory.departure_time}`)
      }
      return ''
    },

    arrivalDateTime () {
      if (this.transactionHistory.arrival_date !== '' && this.transactionHistory.arrival_time !== '') {
        return this.dateTimeFormatterLong(`${this.transactionHistory.arrival_date} ${this.transactionHistory.arrival_time}`)
      }
      return ''
    },

    filterCompanies () {
      return [].concat({ text: 'All', value: 'All' }, this.list.companies)
    },

    excelFileName () {
      const status = this.tableHistories.filter.status
      const dateFrom = String(this.tableHistories.filter.date_from || '').replaceAll('-', '')
      const dateTo = String(this.tableHistories.filter.date_to || '').replaceAll('-', '')

      return `${status}-Reservations-${dateFrom}-${dateTo}.xls`
    },

    isValidForExport () {
      const validDateFrom = ![null, '', ' '].includes(this.tableHistories.filter.date_from)
      const validDateTo = ![null, '', ' '].includes(this.tableHistories.filter.date_to)
      const hasSchedules = this.tableHistories.totalRows >= 1

      return validDateFrom && validDateTo && hasSchedules
    },

    isDriverVisible () {
      return ['Service Driver', 'Service Driver And Vehicle'].includes(this.transactionHistory.reservation_type)
    },

    isVehicleVisible () {
      return ['Service Vehicle', 'Service Driver And Vehicle'].includes(this.transactionHistory.reservation_type)
    }
  },

  mounted () {
    core.index()
    this.getCompanies()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_company: filter.company,
        filter_type: filter.reservation_type,
        filter_status: filter.status,
        filter_from: filter.date_from,
        filter_to: filter.date_to,
        filter_text: filter.search
      })

      return await SLOReservation.histories(filters).then(
        ({ data }) => {
          this.tableHistories.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tableHistories.totalRows = 0
        return []
      })
    },

    async exportProvider () {
      if (!this.isValidForExport) {
        return []
      }

      const filters = this.objectToUrl({
        filter_company: this.tableHistories.filter.company,
        filter_type: this.tableHistories.filter.reservation_type,
        filter_status: this.tableHistories.filter.status,
        filter_date_from: this.tableHistories.filter.date_from,
        filter_date_to: this.tableHistories.filter.date_to,
        filter_text: this.tableHistories.filter.search
      })

      return await SLOReservation.exports(filters).then(
        ({ data }) => {
          return data
        }
      ).catch(() => {
        return []
      }).finally(() => {
        this.state.exporting = false
      })
    },

    async getCompanies () {
      return await SSharedList.getCompanies().then(
        ({ data }) => {
          this.list.companies = data.map(
            company => ({
              text: company.name,
              value: company.id
            })
          )
        }
      ).catch(() => {
        this.list.companies = []
      })
    },

    startExport () {
      if (!this.isValidForExport) {
        return
      }

      this.state.exporting = true
    },

    finishExport () {
      this.state.exporting = false
    },

    onView (item) {
      this.transactionHistory.id = item.id
      this.transactionHistory.created_at = this.dateTimeFormatter(item.created_at)
      this.transactionHistory.status = item.status
      this.transactionHistory.user_name = item.user_name
      this.transactionHistory.company_name = item.company_name
      this.transactionHistory.organization_name = item.organization_name
      this.transactionHistory.reservation_type = item.reservation_type
      this.transactionHistory.departure_date = item.departure_date
      this.transactionHistory.departure_time = item.departure_time
      this.transactionHistory.arrival_date = item.arrival_date
      this.transactionHistory.arrival_time = item.arrival_time
      this.transactionHistory.vehicle_type_name = item.vehicle_type_name
      this.transactionHistory.passenger_count = item.passenger_count
      this.transactionHistory.purpose = item.purpose
      this.transactionHistory.vehicle_name = item.vehicle_name
      this.transactionHistory.vehicle_license_plate = item.vehicle_license_plate
      this.transactionHistory.vehicle_registration_expiry = item.vehicle_registration_expiry
      this.transactionHistory.vehicle_seat_capacity = item.vehicle_seat_capacity
      this.transactionHistory.driver_name = item.driver_name
      this.transactionHistory.driver_license_type = item.driver_license_type
      this.transactionHistory.driver_license_number = item.driver_license_number
      this.transactionHistory.driver_license_expiry = item.driver_license_expiry
      this.transactionHistory.processed_by_name = item.processed_by_name
      this.transactionHistory.processed_by_remarks = item.processed_remarks
      this.transactionHistory.processed_at = this.dateTimeFormatter(item.processed_at)
      this.transactionHistory.file_path = item.file_path
      this.transactionHistory.attachments = item.attachments
      this.$bvModal.show('modal-view-form')
    },

    onPreviewAttachment (item) {
      this.file.name = item.file_original_name
      this.file.preview = this.getImageUrl(`${this.transactionHistory.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    }
  }
}
</script>
